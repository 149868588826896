class Counter extends HTMLElement {
    constructor() {
        super();
        this.min = 0;
        this.max = 0;
        this.value = 0;
        this.inputEl = document.createElement('input');
        this.inputEl.className = 'bg-light absolute inset-0 w-full h-full text-center';
        this.inputEl.addEventListener('input', this.changeHandler.bind(this));
        this.minusButton = document.createElement('button');
        this.minusButton.className = 'btn btn-sm btn-icon';
        this.minusButton.innerHTML = '-';
        this.minusButton.addEventListener('click', this.decrement.bind(this));
        this.plusButton = document.createElement('button');
        this.plusButton.className = 'btn btn-sm btn-icon ml-auto';
        this.plusButton.innerHTML = '+';
        this.plusButton.addEventListener('click', this.increment.bind(this));
    }
    setValue(value) {
        this.value = Math.max(this.min, Math.min(this.max, isNaN(parseInt(value)) ? 0 : parseInt(value)));
        this.inputEl.value = this.value.toString();
    }
    decrement() {
        this.setValue(this.value - 1);
    }
    increment() {
        this.setValue(this.value + 1);
    }
    changeHandler(event) {
        var _a;
        this.setValue(((_a = event.target) === null || _a === void 0 ? void 0 : _a.value) || '0');
    }
    connectedCallback() {
        this.classList.add(...'bg-light flex p-2 relative gap-7'.split(' '));
        const min = parseInt(this.getAttribute('min') || '');
        const max = parseInt(this.getAttribute('max') || '');
        const value = parseInt(this.getAttribute('value') || '');
        this.min = isNaN(min) ? -Infinity : min;
        this.max = isNaN(max) ? Infinity : max;
        this.setValue(value);
        this.appendChild(this.inputEl);
        this.appendChild(this.minusButton);
        this.appendChild(this.plusButton);
    }
    static get observedAttributes() {
        return ['value'];
    }
    attributeChangedCallback(name, oldValue, newValue) {
        switch (name) {
            case 'value':
                {
                    this.setValue(newValue);
                }
                break;
        }
    }
}
function register() {
    customElements.define('c-counter', Counter);
}
export default { register };
